<template>
  <div class="applications-container">
      <template v-for="application in applications" :key="application.id">
         <div v-if="user_role>=application.permission" class="app-data" @click="handle_app_redirect_to(application.route,application.id)">
             <i v-if="!application.icon.includes('https')"  class="material-icons">{{application.icon}}</i>
             <img v-if="application.icon.includes('https') && !application.img_loaded" src="../assets/images/defualt_photo.png">
             <img v-show="application.icon.includes('https') && application.img_loaded" :src="application.icon" @load="is_img_load(application)">
             <p style="text-align:center;">{{application.name}}</p>
             <div @click.stop="handle_app_redirect_to(application.route_manage,application.id)" v-if="application.route_manage && user_role>=99" class="admin-btn">
                 <i class="material-icons">settings</i>
             </div>
         </div>
      </template>
  </div>
</template>
 
<script>
import { computed, onMounted, ref } from '@vue/runtime-core'
import {projectFirestore} from '../firebase/config'
import router from '../router'
import store from '../store'

export default {
    setup(){
        const is_img_load = (application)=>{
            application.img_loaded = true
        }
        const applications = ref([])
        const handle_app_redirect_to=(route,id)=>{
            if(route.includes('https')){
                window.open(route,"_blank")
            }else{
                router.push({name:route,params:{id:id}})
            }
        }
        const user_role = ref(computed(()=>{
            if(store.getters.role){
                return store.getters.role
            }
            return 0
        }))
        
        onMounted(async()=>{
            await get_application_from_db()
        })
        const get_application_from_db=async()=>{
            const docs = await projectFirestore.collection('Applications').get()
            applications.value = docs.docs.map(doc=>doc.data())
        }
        
        return{
            applications,
            handle_app_redirect_to,
            user_role,
            is_img_load,
        }
    }
}
</script>

<style scoped>
    .applications-container{
        padding: 5px;
        width: 100%;
        height: 100%;
        display: grid;
        grid-template-columns:repeat(auto-fill,minmax(300px, 1fr));
        grid-auto-rows: 200px;
        grid-gap: 5px;
        color: #fff;
        overflow-y: auto;
    }
    @media screen and (max-width: 600px) {
        .applications-container{
             grid-template-columns:repeat(auto-fill,minmax(140px, 1fr));
        }
    }
    .applications-container .app-data{
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background: var(--secondary);
        border-radius: 15px;
        cursor: pointer;
    }
    .applications-container .app-data p{
        font-size: 2rem;
    }
    /* @media screen and (max-width: 600px) {
        .applications-container .app-data img{
            width: 64px;
        }
    } */
    i{
        color: #fff;
        font-size: 100px;
    }
    .admin-btn{
        position: absolute;
        top: 5px;
        right: 5px;
        width: 30px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        z-index: 10;
        background: var(--warning);
        
    }
    .admin-btn i{
        font-size: 20px;
    }
</style>